import {
  GET_CATEGORY,
  GET_CITY,
  GET_DISTRICT,
  GET_WARD,
  GET_PRODUCT_ERP,
  CHECK_BAO_HANH,
  GET_THUONGHIEU,
  ADD_BAO_HANH,
  TRA_CUU_BAO_HANH,
  GET_PRODUCTNAME,
  GET_KICHHOATBAOHANH,
  GET_MADAILYBAN,
  GET_TRUNGTAMBAOHANH,
  GET_TAOBAOHANHWEB,
  GET_TENSANPHAM,
  GET_DanhSachPhieuBaoHanh,
  GET_DemDanhSachPhieuBaoHanh,
  GET_YEUCAUDETAIL,
  UPLOAD_FILE,
  DOIMATKHAU,
  ADDFEEDBACK,
  GET_LOGIN,
  CAPNHAT_IMAGEBAOHANH,
  LISTIMAGECAPNHAT,
  DANH_SACH_SAN_PHAM_ECOVACS,
  DANH_SACH_PHU_KIEN_ECOVACS,
  DANH_SACH_THUONG_HIEU_ECOVACS,
  DANH_SACH_DONG_SAN_PHAM_ECOVACS,
  GET_TENHANG,
  GET_PRODUCTNAMEKICHOAT,
} from "../type"
import { getProductNameKichHoat } from "./action"



const initialState = {
  loading: false,
  listCategory: [],
  listCity: [],
  listDistrict: [],
  listWard: [],
  getMaDaiLyBan: [],
  getProductName: {},
  listThuongHieu: [],
  listProductErp: [],
  getTaoBaoHanhWeb: [],
  UploadFile: [],
  hanBaoHanh: null,

  traCuuBaoHanh: null
}

const categories = (state = initialState, action) => {

  switch (action.type) {
    case GET_LOGIN:
      return {
        ...state,
        login: action.data
      }
    case GET_CATEGORY:
      return {
        ...state,
        listCategory: action.data
      }
    case GET_THUONGHIEU:
      return {
        ...state,
        listThuongHieu: action.data
      }
    case GET_PRODUCTNAME:
      return {
        ...state,
        getProductName: action.data
      }
      case GET_PRODUCTNAMEKICHOAT:
        return {
          ...state,
          getProductNameKichHoat: action.data
        }
    case GET_TAOBAOHANHWEB:
      return {
        ...state,
        getTaoBaoHanhWeb: action.data
      }
    case GET_TENSANPHAM:
      return {
        ...state,
        getTenSanPham: action.data
      }
      case LISTIMAGECAPNHAT:
        return {
          ...state,
          getListImage: action.data
        }
    case GET_DemDanhSachPhieuBaoHanh:
      return {
        ...state,
        getDemDanhSachPhieuBaoHanh: action.data,
        loading: false,
      }
    case GET_DanhSachPhieuBaoHanh:
      return {
        ...state,
        getDanhSachPhieuBaoHanh: action.data,
        loading: false,
      }
    case GET_YEUCAUDETAIL:
      return {
        ...state,
        getYeuCauDetail: action.data
      }


    case GET_KICHHOATBAOHANH:
      return {
        ...state,
        getKichHoatBaoHanh: action.data
      }
    case GET_TRUNGTAMBAOHANH:
      return {
        ...state,
        getTrungTamBaoHanh: action.data
      }
    case GET_MADAILYBAN:
      return {
        ...state,
        getMaDaiLyBan: action.data
      }
    case GET_CITY:
      return {
        ...state,
        listCity: action.data
      }
    case GET_DISTRICT:
      return {
        ...state,
        listDistrict: action.data
      }
    case GET_WARD:
      return {
        ...state,
        listWard: action.data
      }
    case GET_PRODUCT_ERP:
      return {
        ...state,
        listProductErp: action.data
      }
    case CHECK_BAO_HANH:
      return {
        ...state,
        hanBaoHanh: action.data
      }
    case ADD_BAO_HANH:
      return {
        ...state
      }
    case TRA_CUU_BAO_HANH:
      return {
        ...state,
        traCuuBaoHanh: action.data
      }
    case UPLOAD_FILE:
      return {
        ...state,
        UploadFile: action.data
      }
    case DOIMATKHAU:
      return {
        ...state,
        DoiMatKhau: action.data
      }
    case CAPNHAT_IMAGEBAOHANH:
      return {
        ...state,
        updateImageBaoHanh: action.data
      }

    case ADDFEEDBACK:
      return {
        ...state,
        AddFeedback: action.data
      }
      case DANH_SACH_SAN_PHAM_ECOVACS:
        return {
          ...state,
          danhSachSanPhamEcovacs: action.data
        }
        case DANH_SACH_PHU_KIEN_ECOVACS:
          return {
            ...state,
            danhSachPhuKien: action.data
          }
          case DANH_SACH_THUONG_HIEU_ECOVACS:
          return {
            ...state,
            danhSachThuongHieu: action.data
          }
         
          case DANH_SACH_DONG_SAN_PHAM_ECOVACS:
          return {
            ...state,
            danhSachDSP: action.data
          }
    default:
      return state
  }
}

export default categories